import React from 'react'
import { Landing, Navbar, Services, About, Machinery, Contact, Footer } from '../components'
// import image1 from '../assets/Rectangle.png'
const Main = () => {
  return (
   
    // this is the main page with the navbar and landing page section, about us section, and contact us section. the links in the navbar will take you to the different sections of the page. onclick will scroll to the section of the page.
    <div className="flex flex-col min-h-screen">
        <Navbar />
    <section id="landing">
        <Landing />
    </section>
    <section id="services" className='mt-12'>
        <Services />
    </section>

   

    <section id="about">
       <About />
    </section>

  
    <section id="machinery">
        <Machinery />
    </section>
    

    <section id="contact">
        <Contact />
    </section>
    
{/*  footer section */}
    <section>
      
        <Footer />
    </section>

</div>
    
   
  )
}

export default Main