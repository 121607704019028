import React, {useState} from 'react'
import {BsTelephone} from 'react-icons/bs'
import {PiMapPinLight} from 'react-icons/pi'
import {GoMail} from 'react-icons/go'
import { BsCheck } from 'react-icons/bs'
const Contact = () => {

    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = {
        name,
        companyName,
        email,
        message,
      };
    
      try {
        const response = await fetch("/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
    
        if (response.ok) {
          setSuccess(true);
          // Clear the form inputs if needed
          setName("");
          setCompanyName("");
          setEmail("");
          setMessage("");
        } else {
          console.error("Failed to send email");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    

  return (
    <div className='flex flex-col md:flex-row px-4 gap-8 mt-24 pb-36 md:w-[90%] mx-auto '>
       <div className="flex flex-col items-start pr-4 flex-1 gap-16 ">
        <div className="flex flex-col items-start ">
        <h1 className='text-[2.5rem] text-gray-800 font-headings md:text-left pr-4'>
        Reach out to us, We are here to satisfy your sealing needs.
        </h1>
        <hr className='w-32 h-1 bg-primary rounded-full my-5'/>
        <p className=' md:w-[80%] text-base text-gray-600  md:text-left'>
        At Green Eagle Cork Seals, we prioritize customer satisfaction and ensure to provide quality and efficient services, we take our commitment to our customers seriously. 
        Reach out to us and let us know what you want and we would be of service..
        </p>
        </div>
        <div className="flex flex-col gap-8">
        <div className="flex gap-4">
            <BsTelephone className="text-2xl" />
            <p className="text-base font-headings">
              <a href="tel:+2348056491183">Marketing: +234 805 649 1183,</a>
              <a href="tel:+2348056491271"> Admin: +234 805 649 1271</a>
            </p>
          </div>
          <div className="flex gap-4">
            <PiMapPinLight className="text-2xl" />
            <p className="text-base font-headings">
              <a
                href="https://maps.google.com/maps?q=No%201,%20Mobolaji%20Johnson%20Avenue%20Oregun,%20Ikeja,%20Lagos."
                target="_blank"
                rel="noopener noreferrer"
              >
                No 1, Mobolaji Johnson Avenue Oregun, Ikeja, Lagos.
              </a>
            </p>
          </div>
          <div className="flex gap-4">
            <GoMail className="text-2xl" />
            <p className="text-base font-headings">
              <a href="mailto:Greeneagle@gmail.com">
                ademola.musa@sevenup.org
              </a>
            </p>
          </div>
        </div>
       </div>


        <div className="flex-1 ">
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 px-4">
          <div className="flex flex-col gap-4">
            <label htmlFor="from_name" className="text-base font-text">
              Name
            </label>
            <input
              type="text"
              name="from_name"
              id="from_name"
              className="border border-primary rounded-sm p-2 focus:outline-primary"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor="company-name" className="text-base font-text">
              Company Name
            </label>
            <input
              type="text"
              name="company-name"
              id="company-name"
              className="border border-primary rounded-sm p-2 focus:outline-primary"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor="email" className="text-base font-text">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="border border-primary rounded-sm p-2 focus:outline-primary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor="message" className="text-base font-text">
              Leave A Message
            </label>
            <textarea
              name="message"
              id="message"
              cols="20"
              rows="10"
              className="border border-primary rounded-sm p-2 focus:outline-primary resize-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="relative">
            <button
              type="submit"
              className="mx-4 w-[270px] md:w-[430px] h-[58px] bg-primary text-white md:mx-20 my-4 px-4 py-2 font-headings rounded-sm before:absolute md:before:mx-20 before:mx-4 before:border-2 before:border-primary before:left-2 before:top-5 before:w-[270px] md:before:w-[430px] before:h-[58px] before:content-'' md:before:hover:top-4 md:before:hover:left-0  before:hover:transition-all before:hover:duration-500 before:hover:ease-in-out"
            >
              {
                  success ? <span className='flex items-center justify-center gap-2'>
                      <p>Message Sent</p>
                      <BsCheck className='text-2xl'/>
                  </span>
                   : 'Send Message'
              }
            </button>
          </div>
        </form>
        </div>
    </div>
  )
}

export default Contact