import React from 'react'
import image1 from '../../assets/Rectangle 38.png'
import image2 from '../../assets/Rectangle 39.png'
import image3 from '../../assets/Rectangle 40.png'

const Machinery = () => {
  return (
    <div className='flex flex-col items-center mt-32  pb-36 md:w-[80%] mx-auto '>
       <div className='flex flex-col items-center '>
        <h1 className='text-[2.5rem] text-gray-700 font-headings text-center'>
        Our Advanced Technology <br />      And Machinery
        </h1>
        <hr className='w-32 h-1 bg-gray-800 rounded-full my-5'/>
        <p className=' w-[80%] text-base text-gray-500 md:w-[60%] text-center'>
    Our machinery integrates the latest technological advancements, ensuring you stay ahead in the competitive landscape. We offer solutions that embrace industry principles, enabling smarter and more connected operations. 
        </p>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:p-12 bg-line bg-no-repeat '
        style={{backgroundSize: 'contain', backgroundPosition: 'bottom',}}
        >
            <div className='flex flex-col items-center justify-center h-max  m-5 px-4 py-2 text-center gap-2'>
                <p className='text-[12px] textgraye-800 font-headings'>COMPUTER TO PLATE FOR PRINTING</p>
                <img src={image1} alt="" className='  md:w-[300px] md:h-[300px]' />
                
            </div>
            <div className='flex flex-col items-center justify-center h-max  m-5 px-4 py-2 text-center gap-2'>
                <p className='text-[12px] text-gray-800 font-headings'>SACMI LINERS FOR CROWN PRODUCTION</p>
                <img src={image2} alt="" className='  md:w-[400px] md:h-[400px]' />
            </div>
            <div className='flex flex-col items-center justify-center h-max  m-5 px-4 py-2 text-center gap-2'>
                <p className='text-[12px] text-gray-800 font-headings'>CRABTREE PRINTING LINES FOR DECORATING</p>
                <img src={image3} alt="" className=' mb-4 md:mb-0 md:w-[300px] md:h-[300px]' />
            </div>
        </div>
    </div>
  )
}

export default Machinery