import React from 'react'
import {MdHdrStrong} from 'react-icons/md'
import {SlBadge} from 'react-icons/sl'
import {BsFillBuildingsFill} from 'react-icons/bs'
import {PiLightbulbFilament} from 'react-icons/pi'
const Services = () => {
  return (
    <div className='flex flex-col items-center justify-center md:w-[70%] mx-auto min-h-screen my-8'>
        <h1 className='text-[2.5rem] text-gray-800 font-headings text-center'>What <br /> We Have To Offer You!</h1>
        <hr className='w-32 h-1 bg-primary rounded-full my-5'/>
        <p className=' w-[80%] text-base text-gray-600 md:w-[60%] text-center'>
        At Green Eagle  Cork Seals we take pride in producing crown corks that define quality, innovation, and reliability. With years of expertise in the industry, we bring you a range of crown corks 
        that not only seal beverages but also uphold the essence of your brand.
        </p>
        <div className=' flex flex-wrap  justify-center mt-10'>
            <div className='flex flex-col items-center justify-center w-80 h-max  m-5 px-4 py-2 text-center gap-2'>
                <MdHdrStrong className='text-6xl text-primary'/>
                <h1 className='text-2xl text-gray-800 font-headings'>Assured Durabilty</h1>
                <p className='text-gray-600'>
                We produce unwavering durable products for every journey, built to last and perfectly fit your needs.
                </p>
            </div>
            <div className='flex flex-col items-center justify-center w-80 h-max  m-5 px-4 py-2 text-center gap-2'>
                <SlBadge className='text-6xl text-primary'/>
                <h1 className='text-2xl text-gray-800 font-headings'> Trust And Sustainability</h1>
                <p className='text-gray-600'>
               
We earn trust through excellence and have produced crown seals for reputable companies around the globe.
                    </p>
            </div>
            <div className='flex flex-col items-center justify-center w-80 h-max  m-5 px-4 py-2 text-center gap-2'>
                <BsFillBuildingsFill className='text-6xl text-primary'/>
                <h1 className='text-2xl text-gray-800 font-headings'> Modern Infrastructure</h1>
                <p className='text-gray-600'>
               
We always deliver the best quality products in our modern facility and we never disappoint.
                    </p>
            </div>
            <div className='flex flex-col items-center justify-center w-80 h-max  m-5 px-4 py-2 text-center gap-2'>
                <PiLightbulbFilament className='text-6xl text-primary'/>
                <h1 className='text-2xl text-gray-800 font-headings'>Innovative Design</h1>
                <p className='text-gray-600'>
                
From classic to contemporary, we offer a variety of designs that resonate with your target audience.
                    </p>
            </div>
        </div>
    </div>
  )
}

export default Services