import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import logo from '../../assets/logo.png';
import { HiMenu } from 'react-icons/hi';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
   
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth', 
      block: 'start', 
    });
   
    setMobileMenuOpen(false);
  };

  return (
    <div className="bg-[#242E35] sticky top-0 z-50 flex flex-col h-[70px]">
      <div className="flex items-center justify-between w-full px-4 py-2 md:px-12">
        <div className="flex items-center">
          <img src={logo} alt="logo" className="mr-2" />
        </div>
        <div className="md:hidden">
          <HiMenu
            className="text-white text-3xl cursor-pointer"
            onClick={toggleMobileMenu}
          />
        </div>
        <div className="hidden md:flex gap-8 items-center text-white">
          <NavLink
            to="/"
            className="mr-4 font-headings relative"
            onClick={() => scrollToSection('landing')}
          >
            Home
          </NavLink>
          <NavLink
            to="/"
            className="mr-4 font-headings relative"
            onClick={() => scrollToSection('services')}
          >
            Services
          </NavLink>
          <NavLink
            to="/"
            className="mr-4 font-headings relative"
            onClick={() => scrollToSection('about')}
          >
            About Us
          </NavLink>
          <NavLink
            to="/"
            className="mr-4 font-headings relative"
            onClick={() => scrollToSection('machinery')}
          >
            Machinery
          </NavLink>
          <NavLink
            to="/"
            className="mr-4 font-headings border border-white px-4 py-2 rounded-sm hover:bg-white hover:text-[#242E35]"
            onClick={() => scrollToSection('contact')}
          >
            Contact Us
          </NavLink>
        </div>
      </div>
      {mobileMenuOpen && (
        <div
          className={`md:hidden px-4 py-2 bg-[#242E35] transition duration-500 ease-in-out ${
            mobileMenuOpen ? 'block' : 'hidden'
          }`}
        >
          <NavLink
            to="/"
            onClick={() => {
              toggleMobileMenu();
              scrollToSection('landing');
            }}
            className="block text-white py-2 hover:text-gray-300 font-headings"
          >
            Home
          </NavLink>
          <NavLink
            to="/"
            onClick={() => {
              toggleMobileMenu();
              scrollToSection('about');
            }}
            className="block text-white py-2 hover:text-gray-300 font-headings"
          >
            About Us
          </NavLink>
          <NavLink
            to="/"
            onClick={() => {
              toggleMobileMenu();
              scrollToSection('machinery');
            }}
            className="block text-white py-2 hover:text-gray-300 font-headings"
          >
            Machinery
          </NavLink>
          <NavLink
            to="/"
            onClick={() => {
              toggleMobileMenu();
              scrollToSection('contact');
            }}
            className="block text-white py-2 hover:text-gray-300 font-headings"
          >
            Contact Us
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Navbar;
