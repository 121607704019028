import React, {useEffect, useState, useMemo} from 'react'
import image1 from '../../assets/pepsi.png'
import image5 from '../../assets/pepsi5.png'
import image6 from '../../assets/pepsi6.png'
const Landing = () => {
    const images = useMemo(() => [image1, image5, image6], []);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        // Increment the index to show the next image in the array
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);
      return () => clearInterval(interval);
    }, [images]);
  


  return (
    <div className=" h-[100vh] flex justify-center gap-4 flex-col md:h-[80vh] bg-hero bg-cover bg-center bg-no-repeat  md:flex-row">
    <div className="md:w-3/5 md:h-full p-4 md:p-0">
      <div className="flex gap-4 flex-col md:justify-center md:h-[85vh] md:mx-12">
        <h1 className="text-3xl md:text-[40px] lg:text-5xl font-bold text-white font-headings md:mx-20 leading-loose ">
          Your First Choice Plastic And Metal Crown Packaging Manufacturer
        </h1>
        <p className="text-lg md:text-[16px] text-white font-landing md:pl-20">
          With Crown Cork Int’l S.A as the major shareholder, the company is the only supplier of metal crown caps and plastic crates to Seven-Up Bottling Company Nigeria Ltd.
        </p>
        <div className="relative">
            <button className="mx-16 w-[170px] h-[58px] bg-primary text-white md:mx-20 my-4 px-4 py-2 font-headings rounded-sm before:absolute md:before:mx-20 before:mx-16 before:border-2 before:border-primary before:left-2 before:top-5 before:w-[170px] before:h-[58px] before:content-'' md:before:hover:top-4 md:before:hover:left-0  before:hover:transition-all before:hover:duration-500 before:hover:ease-in-out">
                <a href="#about">Learn More</a>
            </button>
        </div>
      </div>
    </div>

    <div className="md:w-2/5 relative md:h-full">
      
      <div className="flex items-center justify-center md:items-center md:h-[65vh] h-full">
        <img
          src={images[currentImageIndex]}
          alt="pepsi"
          className=" w-[200px] h-[200px] md:w-[300px] md:h-[300px]"
        />
      </div>
      <div className="bg-white w-[300px] h-[2px] rounded-full mx-auto mt-4 md:hidden"></div>
    </div>
  </div>
  )
}

export default Landing