import React from 'react'

const Footer = () => {

    const date = new Date().getFullYear();

  return (
    <div className='flex flex-col items-center justify-between gap-6 w-full h-max bg-[#242E35] text-white p-6'>
     <div className='flex flex-col md:flex-row items-start justify-around w-full gap-4 mt-8'>
       <div className="">
        <h3 className='text-xl font-headings font-normal text-primary '>
        Green Eagle Cork Seals Nigeria Limted 
        </h3>
        <p className='text-sm font-text font-normal text-[#fcfcfc] mt-3 '>
        Satisfy your sealing needs with us.
        </p>
       </div>

       <div className="inline-flex items-start gap-[60px] relative">
      <div className="inline-flex flex-col items-start gap-[6.01px] relative flex-[0_0_auto]">
        <div className="relative w-fit mt-[-1.00px] font-headings font-normal text-[#fcfcfc] text-[16px] ">
          Offers
        </div>
        <div className="inline-flex flex-col items-start gap-[4.01px]">
          <p className="relative self-stretch font-text text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Durability
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Trust
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Modern
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Innovative
            </span>
          </p>
        </div>
      </div>
      <div className="inline-flex flex-col items-start gap-[6.01px] relative flex-[0_0_auto]">
        <div className="relative w-fit mt-[-1.00px] font-headings font-normal text-[#fcfcfc] text-[16px] ">
          About Us
        </div>
        <div className="inline-flex flex-col items-start gap-[4.01px]">
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Our Vision
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Our Mission
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Certifications
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              {""}
            </span>
          </p>
        </div>
      </div>
      <div className="inline-flex flex-col items-start gap-[6.01px] relative flex-[0_0_auto]">
        <div className="relative w-fit mt-[-1.00px] font-headings font-normal text-[#fcfcfc] text-[16px] ">
          Machinery
        </div>
        <div className="inline-flex flex-col items-start gap-[4.01px]">
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Printing
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Coating
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              Decorating
              <br />
            </span>
          </p>
          <p className="relative self-stretch font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
            <span className="font-text font-normal text-[#fcfcfc] text-[12px] tracking-[0] leading-[14.0px]">
              {""}
            </span>
          </p>
        </div>
      </div>
      <div className="inline-flex flex-col items-start gap-[10.01px] relative flex-[0_0_auto]">
        <div className="relative w-[98.13px] h-[19.03px]">
          <div className="relative w-[75px] h-[23px]">
            <div className="absolute top-0 left-0 font-headings font-normal text-[#fcfcfc] text-[16px] ">
              Contact Us
            </div>
          </div>
        </div>
        <div className="relative w-fit font-text font-normal text-[#fcfcfc] text-[12px]  whitespace-nowrap">
          Marketing : +2348056491183
        </div>
        <div className="relative w-fit font-text font-normal text-[#fcfcfc] text-[12px]  whitespace-nowrap">
          Admin : +2348056491271
        </div>
      </div>
       </div>
       
        </div>

         <div className='flex items-center justify-end gap-4 mt-8'>
            <p className='text-sm text-slate-400 text-center'>
            &copy; {date} Green Eagle Cork Seals Nigeria Limted. All Rights Reserved.
            </p>
            {/* <p className='text-sm'>
            Designed by <a href="https://www.linkedin.com/in/oluwatobi-oluwatola-4b1b3a1b2/">Oluwatobi Oluwatola</a>
            </p> */}
        </div>
    </div>
  )
}

export default Footer