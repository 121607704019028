import React from 'react'
import about1 from '../../assets/Group 68.png'
import about2 from '../../assets/Group 73.png'
import image1 from '../../assets/Rectangle.png'
const About = () => {
  return (
    <div className='flex flex-col gap-8 items-center h-max  w-full mx-auto'
        style={{
                background: `url(${image1})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                // backgroundAttachment: 'fixed',
                // minHeight: '100vh',
            }}
    >
        <div className="grid md:grid-cols-2 gap-12 p-4 relative h-max">
            <div className="">
                <img src={about1} alt="" />
            </div>
            <div className=" text-white flex flex-col justify-center items-end text-right relative md:top-[12rem] h-max md:left-[10rem] md:w-[500px]  ">
           <h2 className='text-[2rem] font-headings text-right mt-4'> About Us,  Why you <br /> should work with us.</h2>
             <hr className='w-[100px] h-[2px] bg-white mt-4 mr-2' />
             <div className="flex flex-col text-right mt-6">
                <p className='text-[1.2rem] font-headings px-2'>Try GreenEagleCorkSeals Today </p>

            <p className='font-text text-base whitespace-normal px-2 mt-2' >We are your first choice manufacturer of metal crown corks and plastic crates in Nigeria and West Africa. We are reputed for quality products and excellent processes. Green Eagle Cork Seals was incorporated on March 25, 1976. The company erstwhile location was Matori, 
                Oshodi, Lagos where its production of crowns started. In 1981,
                 the company expanded and established its state 
                 of the art factory in Oregun, Ikeja, Lagos.</p>
             </div>
             
            </div>
        </div>
        <div className=" px-4 grid md:grid-cols-2 gap-12 p-4 relative mt-12 h-max mb-12 ">
            <div className=" ml-6 text-white flex flex-col gap-8 w-9/12 justify-center items-start text-left relative mb-8  ">
               <div className="">
                     <h2 className='text-[2rem] font-headings text-left'> Our Vision</h2>
                     <hr className='w-[100px] h-[2px] bg-white mt-1 ml-1' />
                        <div className="flex flex-col text-left mt-2">
                            <p className='text-base font-text '>To be the first choice crown cork and plastic producer in West Africa Sub-region.</p>
                        </div>
               </div>
               
               <div className="">
                        <h2 className='text-[2rem] font-headings text-left'> Our Mission</h2>   
                        <hr className='w-[100px] h-[2px] bg-white mt-1 ml-1' />
                        <div className="flex flex-col text-left mt-2">
                            <p className='text-base font-text '>To provide our customers with the best quality crown caps and plastic packaging produced to globally recognized standard and anchored on customers satisfaction and capacity development of our employees.</p>
                        </div>
               </div>
                <div className="">
                        <h2 className='text-[2rem] font-headings text-left'> Our Certifications</h2>
                        <hr className='w-[100px] h-[2px] bg-white mt-1 ml-1' />
                        <div className="flex flex-col text-left mt-2">
                            <p className='text-base font-text '>The company is presently certified to ISO 9001:2015 (Quality Management System).</p>
                            <p className='text-base font-text '>The company is presently certified to ISO 22000:2005 (Food Safety Management System).</p>
                        </div>
                </div>
            </div>

            <div className=" relative top-[1rem] h-max md:left-[8rem] md:w-[500px] ">
                <img src={about2} alt="" className='w-full object-contain' />
                
            </div>
        </div>
       
        
    </div>
  )
}

export default About
